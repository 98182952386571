<template>
  <v-dialog v-if="isLoggedIn" v-model="dialog" max-width="1080">
    <template #activator="{ props }">
      <v-btn v-bind="props" variant="plain" class="my-2 footer-menu text-none">
        Feedback
      </v-btn>
    </template>
    <main-card>
      <FeedbackForm @closeDialog="closeDialog()" />
    </main-card>
  </v-dialog>
</template>

<script>
import authControlMixin from "@/mixins/authControlMixin";
import FeedbackForm from "@/components/participants/FeedbackForm";
import MainCard from "@/components/layout/MainCard";

export default {
  name: "FeedbackDialog",
  components: { FeedbackForm, MainCard },
  mixins: [authControlMixin],
  emits: ["createSnackbar"],
  data: () => ({
    dialog: false,
  }),
  methods: {
    closeDialog() {
      this.dialog = false;
      this.$emit("createSnackbar");
    },
  },
};
</script>

<style scoped></style>
